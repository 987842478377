<template>
  <!-- begin::MyVehicles new -->
  <MyVehiclesEdit :vehicle-id="-1" />
  <!-- end::MyVehicles new -->
</template>

<script>
import MyVehiclesEdit from "@/view/pages/manager/vehicles/sender/Edit.vue";

export default {
  name: "MyVehicleNew",
  components: {
    MyVehiclesEdit
  }
};
</script>
